<!--
 * @Author: gyh
 * @Date: 2024-04-12 17:53:40
 * @LastEditTime: 2024-04-18 14:28:39
-->
<!-- 花都 -->
<template>
  <!-- 学生信息 -->
  <p class="first-row">{{ score.name }}&nbsp;同学</p>

  <template v-if="score.showStatus == 1">
    <div class="third-row">
      <div class="first_col">
        恭喜你已获得&nbsp;
        <p class="red_text">
          {{ riseMap[score.awardLevel] || "优秀奖" }}
        </p>
      </div>
      <div class="first_col">很棒，继续加油！</div>
    </div>

    <div class="fourth-row">
      <img
        :src="
          require(`@/assets/home/hdycp/reward${
            [1, 2, 3].includes(score.awardLevel) ? score.awardLevel : 4
          }.png`)
        "
        alt="img"
        class="reward_img"
      />
    </div>

    <div class="fifth-row">
      获奖证书获取，请关注“花都区青少年文化宫”公众号，留意后续通知。
    </div>
  </template>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, inject } from "vue";

const { proxy } = getCurrentInstance();

defineProps({ score: Object });

const riseMap = { 1: "一等奖", 2: "二等奖", 3: "三等奖", 4: "优胜奖" },
  riseTipsMap = {
    1: "这是一个不错的起点，请继续努力，我相信你还有更多的收获。",
    2: "这是一个美好的开始，保持坚持和努力，相信你会获得更多的成就。",
    3: "这体现了你的努力和付出。期待你在未来的挑战中创造更辉煌的成绩。",
    4: "这证明你已做出不菲的努力，请继续攀登，相信你的坚持会有更好的收获。",
  };
</script>

<style lang="less" scoped>
.first-row {
  font-size: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  line-height: 56px;
  margin-top: 80px;
}

.third-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  font-weight: 600;
  color: #00a870;
  margin-top: 24px;

  .first_col {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: center;
  }

  .red_text {
    color: #e34d59;
    font-size: 36px;
  }
}

.fourth-row {
  height: fit-content;
  margin-top: 24px;

  .reward_img {
    width: 480px;
    height: 360px;
  }
}

.fifth-row {
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  margin-top: 24px;
}
</style>
